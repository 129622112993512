import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute: React.FC<{ component: React.ComponentType }> = ({ component: Component }) => {
    const token = localStorage.getItem('token');

    if (!token) {
        return <Navigate to="/login" />;
    }

    return <Component />;
};

export default PrivateRoute;
