import { About } from '../components/About';
import { SocialLinks } from '../components/SocialLinks';

export const Home = () => {
    return (
        <section className='mb-5'>
            <About />
            < SocialLinks />
        </section>
    );
}