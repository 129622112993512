import React from "react";
import { useNavigate } from "react-router-dom";
import { Container, Box, Typography, Avatar, Grid, Paper } from "@mui/material";

interface Project {
  title: string;
  link?: string;
  categoryId?: number;
}

const projects: Project[] = [
  { title: "Développement", link: "/development", categoryId: 8},
  { title: "Dessin", link: "/creations", categoryId: 1 },
  { title: "Écriture", link: "/creations", categoryId: 3 },
  { title: "Modélisation 3D", link: "/creations", categoryId: 4 },
  { title: "Montage", link: "/creations", categoryId: 2 },
];

export const About: React.FC = () => {
  const navigate = useNavigate();

  const handleProjectClick = (project: Project) => {
    if (project.link && project.categoryId !== undefined) {
      navigate(project.link, { state: { categoryId: project.categoryId } });
    }
  };

  return (
    <Container maxWidth="lg" sx={{ pt: 8 }}>
      <Grid container direction="row" justifyContent="center" alignItems="center" spacing={3}>
        <Grid item sm={12} md={1}>
            <Avatar
              src="/images/alexandre-joniec.jpg"
              alt="Alexandre Joniec"
              sx={{ width: 120, height: 120 }}
            />
          </Grid>
          <Grid item sm={12} md={11}>
          <Box ml={{ md: 8 }} component={"div"}>
            <Typography variant="h4" fontWeight="bold" mb={2}>
              À propos de moi
            </Typography>
            <Typography variant="body1" paragraph>
              Bienvenue sur mon portfolio ! Je m'appelle <b>Alexandre Joniec</b> et je suis un passionné de création et de technologie.
              Écrivain, développeur, cherchant a se reconvertir en ludothèque ou en bibliohèque, mon parcours atypique m'a permis d'acquérir une grande diversité de compétences et d'expériences.
            </Typography>
            <Typography variant="body1" paragraph>
              Sur ce portfolio, vous trouverez un aperçu de mes projets et de mes créations. N'hésitez pas à me contacter si vous souhaitez en savoir plus sur mon travail. Merci de votre visite !
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Box mb={8} component={"div"}>
        <Typography variant="h5" fontWeight="bold" mb={2}>
          Mes Projets
        </Typography>
        <Grid container spacing={2}>
          {projects.map((project, index) => (
            <Grid item xs={6} md={4} key={index}>
              {project.link ? (
                <Paper
                  elevation={1}
                  onClick={() => handleProjectClick(project)}
                  sx={{
                    p: 2,
                    textAlign: "center",
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "grey.200",
                      transition: "background-color 0.3s",
                    },
                  }}
                >
                  {project.title}
                </Paper>
              ) : (
                <Paper elevation={1} sx={{ p: 2, textAlign: "center" }}>
                  {project.title}
                </Paper>
              )}
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default About;
