import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Button,
  Box,
  Pagination
} from '@mui/material';

interface Image {
  id: number;
  title: string;
  src: string;
}

interface ImageSelectorProps {
  open: boolean;
  onClose: () => void;
  images: Image[];
  onSelectImage: (imageId: number) => void;
  currentPage: number;
  totalPages: number;
  onPageChange: (event: React.ChangeEvent<unknown>, page: number) => void;
  categoryId: number;
}

const ImageSelector: React.FC<ImageSelectorProps> = ({
  open,
  onClose,
  images,
  onSelectImage,
  currentPage,
  totalPages,
  onPageChange,
  categoryId
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle>Choisir une image (Catégorie {categoryId})</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {images.map((image) => (
            <Grid item xs={12} sm={6} md={4} key={image.id}>
              <Card
                onClick={() => onSelectImage(image.id)}
                sx={{ cursor: "pointer" }}
              >
                <CardMedia
                  component="img"
                  height="140"
                  image={`${image.src}`}
                  alt={image.title}
                />
                <CardContent>
                  <Typography variant="body2" color="textSecondary">
                    {image.title}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Annuler
        </Button>
        <Box mt={4} display="flex" justifyContent="center" width="100%" component={"div"}>
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={onPageChange}
            color="primary"
          />
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ImageSelector;