const API_BASE_URL = 'https://api.inspiredbylepidopterae.fr/api';

interface RequestOptions extends RequestInit {
  token?: string;
  data?: any;
}

async function refreshToken() {
  const refreshToken = localStorage.getItem('refreshToken');
  if (!refreshToken) {
    throw new Error('No refresh token available');
  }

  const response = await fetch(`${API_BASE_URL}/login/refresh-token`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ refreshToken }),
  });

  if (!response.ok) {
    console.error('Failed to refresh token:', response.status, await response.text());
    throw new Error('Failed to refresh token');
  }

  const data = await response.json();
  localStorage.setItem('token', data.accessToken);
  return data.accessToken;
}

async function apiRequest(endpoint: string, options: RequestOptions = {}) {
  const { token, data, ...fetchOptions } = options;

  async function executeRequest() {
    const headers = new Headers(fetchOptions.headers || {});

    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }

    if (data && !(data instanceof FormData)) {
      headers.set('Content-Type', 'application/json');
      fetchOptions.body = JSON.stringify(data);
    } else if (data instanceof FormData) {
      fetchOptions.body = data;
    }

    const response = await fetch(`${API_BASE_URL}${endpoint}`, {
      ...fetchOptions,
      headers,
    });

    if (response.status === 401) {
      // Token expired, try to refresh
      try {
        const newToken = await refreshToken();
        headers.set('Authorization', `Bearer ${newToken}`);
        return fetch(`${API_BASE_URL}${endpoint}`, {
          ...fetchOptions,
          headers,
        });
      } catch (error) {
        console.error('Failed to refresh token:', error);
        throw error;
      }
    }

    return response;
  }

  const response = await executeRequest();
  
  if (!response.ok) {
    const errorResponse = await response.json(); // Try to parse error details
    console.error(`HTTP error! status: ${response.status}`, errorResponse);
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return response.json();
}

export const api = {
  get: (endpoint: string, token?: string) => 
    apiRequest(endpoint, { method: 'GET', token }),

  post: (endpoint: string, data: any, token?: string) => 
    apiRequest(endpoint, { method: 'POST', data, token }),

  put: (endpoint: string, data: any, token?: string) => 
    apiRequest(endpoint, { method: 'PUT', data, token }),

  delete: (endpoint: string, token?: string) => 
    apiRequest(endpoint, { method: 'DELETE', token }),
};