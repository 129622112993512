import React, { useState, useEffect } from 'react';
import { useForm, SubmitHandler } from "react-hook-form";
import emailjs from '@emailjs/browser';
import { Container, TextField, Button, Typography, Modal, Box } from '@mui/material';

interface FormInputs {
    subject: string;
    email: string;
    message: string;
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export const Contact: React.FC = () => {
    const { register, handleSubmit, formState: { errors }, reset } = useForm<FormInputs>();
    const [showModal, setShowModal] = useState<boolean>(false);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`;
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);

        script.onload = () => {
            if (typeof grecaptcha !== 'undefined') {
                console.log('grecaptcha is ready');
            } else {
                console.error('grecaptcha not defined');
            }
        };

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const onSubmit: SubmitHandler<FormInputs> = async (data, e) => {
        if (typeof grecaptcha === 'undefined') {
            console.error('grecaptcha is not defined');
            return;
        }

        try {
            const token = await grecaptcha.execute('process.env.REACT_APP_RECAPTCHA_SITE_KEY', { action: 'submit' });
            
            emailjs.send('service_krdj54p',
                'template_m43v7cc',
                {
                    subject: data.subject,
                    email: data.email,
                    message: data.message,
                    'g-recaptcha-response': token
                },
                '2dGNfkabr4losjXvm'
            ).then(() => {
                e?.target.reset();
                setShowModal(true);
            }).catch(error => {
                console.error('Failed to send email:', error);
            });
        } catch (error) {
            console.error('reCAPTCHA error:', error);
        }
    };

    return (
        <Container maxWidth="sm">
            <Typography variant="h3" component="h1" gutterBottom>
                Contact
            </Typography>

            <form onSubmit={handleSubmit(onSubmit)}>
                <TextField
                    {...register("subject", { required: true })}
                    label="Sujet"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    error={!!errors.subject}
                    helperText={errors.subject ? "Veuillez compléter ce champ" : ""}
                />
                <TextField
                    {...register("email", {
                        required: "Veuillez compléter ce champ",
                        pattern: {
                            value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                            message: "Format d'email invalide"
                        }
                    })}
                    label="Email"
                    type="email"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    error={!!errors.email}
                    helperText={errors.email ? errors.email.message : ""}
                />
                <TextField
                    {...register("message", { required: true })}
                    label="Message"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    multiline
                    rows={4}
                    error={!!errors.message}
                    helperText={errors.message ? "Veuillez compléter ce champ" : ""}
                />
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ mt: 2 }}
                >
                    Envoyer
                </Button>
            </form>

            <Modal
                open={showModal}
                onClose={() => setShowModal(false)}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={style} component={"div"}>
                    <Typography id="modal-title" variant="h6" component="h2">
                        Email envoyé
                    </Typography>
                    <Typography id="modal-description" sx={{ mt: 2 }}>
                        Votre message a été envoyé avec succès.
                    </Typography>
                    <Button
                        onClick={() => setShowModal(false)}
                        variant="contained"
                        color="primary"
                        sx={{ mt: 2 }}
                    >
                        Fermer
                    </Button>
                </Box>
            </Modal>
        </Container>
    );
};
