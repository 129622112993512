import React, { useState, useRef, DragEvent, useEffect } from "react";
import {
  Container,
  Select,
  MenuItem,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Button,
  Box,
  Grid,
  Pagination,
  TextField,
  Fab,
  Modal,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import { api } from "../../api";

interface Image {
  id: number;
  title: string;
  category: string;
  uploadDate: string;
  src: string;
  alt: string;
  description: string;
}

interface Category {
  id: number;
  name: string;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: "600px",
  maxHeight: "90vh",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflow: "auto",
};

export const ContentManager: React.FC = () => {
  const [images, setImages] = useState<Image[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<number | "All">(
    "All"
  );
  const [editingCategoryId, setEditingCategoryId] = useState<number | null>(
    null
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [open, setOpen] = useState(false);
  const [createArticle, setCreateArticle] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const [file, setFile] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [title, setTitle] = useState("");
  const [alt, setAlt] = useState("");
  const [description, setDescription] = useState("");
  const [categoryId, setCategoryId] = useState<number | "All">("All");
  const [editingImage, setEditingImage] = useState<Image | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    fetchImages();
  }, [currentPage, selectedCategory]);

  const fetchCategories = async () => {
    try {
      const data = await api.get('/categories');
      setCategories(data);
      setError(null);
    } catch (error) {
      console.error("Error fetching categories:", error);
      setError("Failed to fetch categories. Please try again.");
    }
  };

  const fetchImages = async () => {
    try {
      const categoryQueryParam = selectedCategory === "All" ? "" : `&category=${selectedCategory}`;
      const data = await api.get(`/images?page=${currentPage}${categoryQueryParam}`);
      setImages(data.images);
      setTotalPages(data.totalPages);
      setError(null);
    } catch (error) {
      console.error("Error fetching images:", error);
      setError("Failed to fetch images. Please try again.");
    }
  };

    const handleDelete = async (id: number) => {
    // Confirm with the user before proceeding with the delete action
    if (!window.confirm("Are you sure you want to delete this image?")) {
      return;
    }
  
    try {
      const token = localStorage.getItem('token');
  
      if (!token) {
        setError("You are not authenticated. Please log in again.");
        return;
      }
  
      // Attempt to delete the image using the API
      const response = await api.delete(`/images/${id}`, token);
  
      if (!response.ok) {
        const errorData = await response.json();
        setError(errorData.message || "Failed to delete image. Please try again.");
        return;
      }
  
      setImages(images.filter((img) => img.id !== id));
      setError(null);
      alert("Image deleted successfully!");
    } catch (error) {
      console.error("Error deleting image:", error);
      setError("Failed to delete image. Please try again.");
    }
  };
  

  const handleFile = (selectedFile: File) => {
    setFile(selectedFile);
    setPreviewUrl(URL.createObjectURL(selectedFile));
  };

  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFile(e.dataTransfer.files[0]);
    }
  };

  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      handleFile(e.target.files[0]);
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError(null);
  
    if (!file && !editingImage) {
      setError("Please select an image");
      return;
    }
  
    const formData = new FormData();
    if (file) formData.append("file", file);
    formData.append("title", title);
    formData.append("alt", alt);
    formData.append("description", description);
    formData.append("categoryId", String(categoryId));
  
    const token = localStorage.getItem('token');
  
    try {
      let response;
      if (editingImage) {
        response = await api.put(`/images/${editingImage.id}`, formData, token || undefined);
      } else {
        response = await api.post("/images/upload-image", formData, token || undefined);
      }
  
      if (!response || !response.id) {
        throw new Error("Invalid response from the server, missing image ID.");
      }
  
      const imageId = typeof response.id === 'number' ? response.id : parseInt(response.id, 10);
      if (isNaN(imageId)) {
        throw new Error("Invalid image ID received from the server.");
      }
  
      if (createArticle && !editingImage) {
        try {
          await createEmptyArticle(imageId);
        } catch (articleError) {
          console.error("Failed to create empty article:", articleError);
          setError("Image uploaded successfully, but failed to create an empty article.");
          return;
        }
      }
  
      alert(editingImage ? "Image updated successfully!" : "Image uploaded successfully!");
      resetForm();
      fetchImages();
      handleClose();
    } catch (error) {
      console.error("Error processing the image:", error);
      if (error instanceof Error) {
        setError(error.message || "An error occurred while processing the image");
      } else {
        setError("An unexpected error occurred.");
      }
    }
  };

  const handleEditSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  
    if (!editingImage) {
      alert("No image selected for editing.");
      return; // Exit if there's no image being edited
    }
  
    const formData = new FormData();
    formData.append("title", title);
    formData.append("alt", alt);
    formData.append("description", description);
    formData.append("categoryId", String(categoryId));
  
    if (file) {
      formData.append("file", file);
    }
  
    const token = localStorage.getItem('token');
  
    if (!token) {
      alert("You are not authenticated. Please log in again.");
      return;
    }
  
    try {
      const response = await fetch(`https://api.inspiredbylepidopterae.fr/api/images/${editingImage.id}`, {
        method: "PUT",
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        body: formData,
      });
  
      if (response.ok) {
        alert("Image updated successfully!");
        resetForm();
        handleClose();
        fetchImages();
      } else {
        const errorData = await response.json();
        console.error("Error updating image:", errorData);
        alert(errorData.message || "Failed to update image. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while updating the image. Please check your connection and try again.");
    }
  };
  


  const createEmptyArticle = async (imageId: number) => {
    if (!imageId || isNaN(imageId)) {
      throw new Error("Invalid imageId provided");
    }
  
    const token = localStorage.getItem('token');
  
    if (!token) {
      throw new Error("You are not authenticated. Please log in again.");
    }
  
    try {
      // Attempt to create an empty article
      const response = await api.post('/articles', { imageId, content: "" }, token);
  
      if (!response.ok) {
        const errorData = await response.json();
        console.error("Error creating empty article:", errorData);
        throw new Error(errorData.message || "Failed to create an empty article.");
      }
  
      console.log("Empty article created successfully for imageId:", imageId);
    } catch (error) {
      console.error("Error creating empty article:", error);
      throw error;
    }
  };
  

  const handleEdit = (image: Image) => {
    setEditingImage(image);
    setTitle(image.title);
    setAlt(image.alt);
    setDescription(image.description);
    const categoryId =
      categories.find((cat) => cat.name === image.category)?.id || null;
    setEditingCategoryId(categoryId);
    setCategoryId(categoryId || "All");
    setPreviewUrl(image.src);
    setCreateArticle(false);
    setOpen(true);
  };

  const handleOpen = () => {
    resetForm();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    resetForm();
  };

  const resetForm = () => {
    setFile(null);
    setPreviewUrl(null);
    setTitle("");
    setAlt("");
    setDescription("");
    setCategoryId("All");
    setEditingImage(null);
    setCreateArticle(false);
    setEditingCategoryId(null);
  };

  return (
    <Container>
      <Typography variant="h4" component="h2" gutterBottom>
        Content Manager
      </Typography>

      <Box mb={3}>
        <Select
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value as number)}
          variant="outlined"
          fullWidth
        >
          <MenuItem value="All">All</MenuItem>
          {categories.map((cat) => (
            <MenuItem key={cat.id} value={cat.id}>
              {cat.name}
            </MenuItem>
          ))}
        </Select>
      </Box>

      <Grid container spacing={3}>
        {images.map((image) => (
          <Grid item xs={12} sm={6} md={4} key={image.id}>
            <Card>
              <CardMedia
                component="img"
                height="200"
                image={image.src}
                alt={image.title}
              />
              <CardContent>
                <Typography variant="h6" component="h3">
                  {image.title}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {image.category}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {new Date(image.uploadDate).toLocaleDateString()}
                </Typography>
                <Box mt={2}>
                  <Button color="primary" onClick={() => handleEdit(image)}>
                    Edit
                  </Button>
                  <Button color="error" onClick={() => handleDelete(image.id)}>
                    Delete
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Box mt={4} display="flex" justifyContent="center">
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={(e, page) => setCurrentPage(page)}
          color="primary"
        />
      </Box>

      <Fab
        color="primary"
        aria-label="add"
        onClick={handleOpen}
        sx={{ position: "fixed", bottom: 16, right: 16 }}
      >
        <AddIcon />
      </Fab>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            gutterBottom
          >
            {editingImage ? "Edit Image" : "Upload Image"}
          </Typography>
          <Box
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            sx={{
              border: "2px dashed grey",
              borderRadius: "8px",
              textAlign: "center",
              p: 3,
              mb: 3,
              cursor: "pointer",
              "&:hover": {
                borderColor: "primary.main",
              },
            }}
            onClick={() => fileInputRef.current?.click()}
          >
            {previewUrl ? (
              <img
                src={previewUrl}
                alt="Preview"
                style={{
                  maxWidth: "100%",
                  maxHeight: "200px",
                  objectFit: "contain",
                }}
              />
            ) : (
              <Typography>
                Glissez et déposez une image ici, ou cliquez pour sélectionner
                un fichier
              </Typography>
            )}
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileInput}
              accept="image/*"
              style={{ display: "none" }}
            />
          </Box>

          <Box
            component="form"
            onSubmit={editingImage ? handleEditSubmit : handleSubmit}
          >
            <TextField
              label="Titre"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              fullWidth
              required
              margin="normal"
            />
            <TextField
              label="Texte alternatif"
              value={alt}
              onChange={(e) => setAlt(e.target.value)}
              fullWidth
              required
              margin="normal"
            />
            <TextField
              label="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              fullWidth
              multiline
              rows={3}
              margin="normal"
            />
            <Select
              value={editingImage ? editingCategoryId : categoryId}
              onChange={(e) => {
                const value = e.target.value as number;
                setCategoryId(value);
                if (editingImage) {
                  setEditingCategoryId(value);
                }
              }}
              fullWidth
              required
              displayEmpty
              sx={{ mt: 2, mb: 2 }}
            >
              <MenuItem value="" disabled>
                Sélectionnez une catégorie
              </MenuItem>
              {categories.map((category) => (
                <MenuItem key={category.id} value={category.id}>
                  {category.name}
                </MenuItem>
              ))}
            </Select>
            {!editingImage && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={createArticle}
                    onChange={(e) => setCreateArticle(e.target.checked)}
                  />
                }
                label="Create empty article for this image"
              />
            )}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{ mt: 3 }}
            >
              {editingImage ? "Update Image" : "Upload Image"}
            </Button>
          </Box>
        </Box>
      </Modal>
    </Container>
  );
};
