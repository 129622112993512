import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ContentGallery from '../components/ContentGallery';
import { Box, Typography, Select, MenuItem, CircularProgress, FormControl, InputLabel, Alert } from '@mui/material';
import { api } from '../api';

interface Category {
  id: number;
  name: string;
}

interface LocationState {
  categoryId?: number;
}

export const Creation: React.FC = () => {
  const location = useLocation();
  const { state } = location as { state: LocationState | null };
  const initialCategory = state?.categoryId || null;

  const [categories, setCategories] = useState<Category[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<number | null>(initialCategory);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const data = await api.get('/categories');
      setCategories(data);
    } catch (error) {
      console.error('Error fetching categories:', error);
      setError('Failed to load categories. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  return (
    <Box sx={{ flexGrow: 1, px: 4, py: 8 }} component={"div"}>
      <Typography variant="h4" component="h2" gutterBottom>
        Mes dessins
      </Typography>

      <FormControl fullWidth variant="outlined" margin="normal">
        <InputLabel id="category-select-label">Select Category</InputLabel>
        <Select
          labelId="category-select-label"
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value as number)}
          label="Select Category"
        >
          {categories.map((category) => (
            <MenuItem key={category.id} value={category.id}>
              {category.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {selectedCategory !== null && <ContentGallery category={selectedCategory} />}
    </Box>
  );
};
