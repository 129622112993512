import { Routes, Route } from 'react-router-dom';
import { Contact } from './screens/Contact';
import { CustomAppBar } from './components/CustomAppBar';
import { AdminPage } from './screens/AdminPage';
import { Home } from './screens/Home';
import { Container, Box } from '@mui/material';
import { Development } from './screens/Development';
import ArticleScreen from './screens/ArticleScreen';
import ArticleEditor from "./screens/ArticleEditor";
import { Creation } from './screens/Creations';
import AdminLogin from './components/admin/AdminLogin';
import PrivateRoute from './components/PrivateRoute';

function App() {
  return (
    <Container>
      <CustomAppBar />
      <Box mt={3} component={"div"}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path='/creations' element={<Creation />} />
          <Route path="/development" element={<Development />} />
          <Route path="/login" element={<AdminLogin />} />
          <Route path="/admin" element={<PrivateRoute component={AdminPage} />} />
          <Route path="/article/:id" element={<ArticleScreen/>} />
          <Route path="/article-editor/:articleId" element={<ArticleEditor/>} />
        </Routes>
      </Box>
    </Container>
  );
}

export default App;
