import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { NavLink, useLocation } from "react-router-dom";

const pages = [
  { name: "Home", path: "/" },
  { name: "Contact", path: "/contact" },
];

export const CustomAppBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const location = useLocation();

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="fixed" sx={{ width: '100%' }}>
      <Toolbar disableGutters>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box sx={{ display: { xs: "none", md: "flex" }, mr: 1 }} component={"div"}>
              <img
                src="/images/lepidopterae.png"
                alt="Logo"
                style={{ height: "40px" }}
              />
            </Box>
            <Typography
              variant="h6"
              noWrap
              component={NavLink}
              to="/"
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
                flexGrow: 1,
              }}
            >
              Inspired By Lepidopterae
            </Typography>

            {/* Mobile menu icon */}
            <Box sx={{ display: { xs: "flex", md: "none" } }} component={"div"}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
            </Box>

            {/* Mobile logo and title */}
            <Box sx={{ display: { xs: "flex", md: "none" }, flexGrow: 1, alignItems: "center" }} component={"div"}>
              <img
                src="/images/lepidopterae.png"
                alt="Logo"
                style={{ height: "40px", marginRight: "10px" }}
              />
              <Typography
                variant="h5"
                noWrap
                component={NavLink}
                to="/"
                sx={{
                  fontFamily: "monospace",
                  fontWeight: 700,
                  letterSpacing: ".3rem",
                  color: "inherit",
                  textDecoration: "none",
                }}
              >
                Inspired By Lepidopterae
              </Typography>
            </Box>

            {/* Desktop menu items */}
            <Box sx={{ display: { xs: "none", md: "flex" } }} component={"div"}>
              {pages.map((page) => (
                <Button
                  key={page.name}
                  component={NavLink}
                  to={page.path}
                  onClick={handleCloseNavMenu}
                  sx={(theme) => ({
                    my: 2,
                    color: "white",
                    display: "block",
                    backgroundColor: location.pathname === page.path ? theme.palette.primary.dark : 'transparent',
                    '&:hover': {
                      backgroundColor: theme.palette.primary.dark,
                    },
                  })}
                >
                  {page.name}
                </Button>
              ))}
            </Box>

            {/* Mobile menu items */}
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem
                  key={page.name}
                  onClick={handleCloseNavMenu}
                  component={NavLink}
                  to={page.path}
                  sx={{
                    backgroundColor: location.pathname === page.path ? 'action.selected' : 'inherit',
                  }}
                >
                  <Typography textAlign="center">{page.name}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Toolbar>
        </Container>
      </Toolbar>
    </AppBar>
  );
};