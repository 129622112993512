import React, { useState, useEffect } from 'react';
import { Typography, Button, List, ListItem, ListItemText, TextField, Box, Modal, Fab, IconButton } from '@mui/material';
import { Add, Edit, Delete } from '@mui/icons-material';
import ImageSelector from '../ImageSelector';
import { api } from '../../api';

interface Object3D {
    id: number;
    name: string;
    src: string;
    image_id: number;
    upload_date: string;
}

interface Image {
    id: number;
    title: string;
    description: string;
    src: string;
    alt: string;
    category_id: number;
    upload_date: string;
}

export const Object3DManagement: React.FC = () => {
    const [objects, setObjects] = useState<Object3D[]>([]);
    const [images, setImages] = useState<Image[]>([]);
    const [availableImages, setAvailableImages] = useState<Image[]>([]);
    const [newObjectName, setNewObjectName] = useState('');
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [selectedImageId, setSelectedImageId] = useState<number | null>(null);
    const [isImageSelectorOpen, setIsImageSelectorOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
    const [editingObject, setEditingObject] = useState<Object3D | null>(null);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        fetchObjects();
        fetchImages(currentPage);
    }, [currentPage]);

    useEffect(() => {
        updateAvailableImages();
    }, [objects, images]);

    const fetchObjects = async () => {
        try {
            const token = localStorage.getItem('token');
            const data = await api.get('/objects3d', token || undefined);
            setObjects(data);
            setError(null);
        } catch (error) {
            console.error('Erreur lors de la récupération des objets 3D:', error);
            setError('Échec du chargement des objets 3D. Veuillez réessayer.');
        }
    };

    const fetchImages = async (page: number) => {
        try {
            const token = localStorage.getItem('token');
            const data = await api.get(`/images?category=4&page=${page}`, token || undefined);
            setImages(data.images);
            setTotalPages(data.totalPages);
            setError(null);
        } catch (error) {
            console.error('Erreur lors de la récupération des images:', error);
            setError('Échec du chargement des images. Veuillez réessayer.');
        }
    };

    const updateAvailableImages = () => {
        const usedImageIds = new Set(objects.map(obj => obj.image_id));
        const available = images.filter(img => !usedImageIds.has(img.id));
        setAvailableImages(available);
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            setSelectedFile(event.target.files[0]);
        }
    };
    
    const handleUpload = async () => {
        if (!selectedFile) {
            setError('Please select a file to upload.');
            return;
        }
        if (!newObjectName.trim()) {
            setError('Please provide a name for the 3D object.');
            return;
        }
        if (!selectedImageId) {
            setError('Please select an associated image.');
            return;
        }
    
        const formData = new FormData();
        formData.append('name', newObjectName.trim());
        formData.append('file', selectedFile);
        formData.append('image_id', selectedImageId.toString());
    
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                setError('You are not authenticated. Please log in.');
                return;
            }
    
            await api.post('/objects3d', formData, token);
            await fetchObjects();
            resetForm();
            setError(null);
        } catch (error) {
            console.error('Error during upload:', error);
            setError('Failed to upload the 3D object. Please try again.');
        }
    };
    
    const handleEdit = async () => {
        if (!editingObject) {
            setError('No object selected for editing.');
            return;
        }
    
        const formData = new FormData();
        formData.append('name', editingObject.name.trim());
        if (selectedFile) {
            formData.append('file', selectedFile);
        }
        if (selectedImageId) {
            formData.append('image_id', selectedImageId.toString());
        }
    
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                setError('You are not authenticated. Please log in.');
                return;
            }
    
            await api.put(`/objects3d/${editingObject.id}`, formData, token);
            await fetchObjects();
            resetForm();
            setError(null);
        } catch (error) {
            console.error('Error during update:', error);
            setError('Failed to update the 3D object. Please try again.');
        }
    };
    
    const handleDelete = async (id: number) => {
        if (window.confirm('Are you sure you want to delete this 3D object?')) {
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    setError('You are not authenticated. Please log in.');
                    return;
                }
    
                await api.delete(`/objects3d/${id}`, token);
                await fetchObjects();
                setError(null);
            } catch (error) {
                console.error('Error during deletion:', error);
                setError('Failed to delete the 3D object. Please try again.');
            }
        }
    };
    
    const resetForm = () => {
        setNewObjectName('');
        setSelectedFile(null);
        setSelectedImageId(null);
        setIsUploadModalOpen(false);
        setEditingObject(null);
    };

    const getImageForObject = (imageId: number) => {
        return images.find(img => img.id === imageId);
    };

    const uploadModalContent = (
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4 }} component={"div"}>
            <Typography variant="h6">{editingObject ? 'Modifier l\'objet 3D' : 'Ajouter un nouvel objet 3D'}</Typography>
            <TextField
                label="Nom de l'objet"
                value={editingObject ? editingObject.name : newObjectName}
                onChange={(e) => editingObject ? setEditingObject({...editingObject, name: e.target.value}) : setNewObjectName(e.target.value)}
                fullWidth
                margin="normal"
            />
            <input
                accept=".glb,.gltf"
                style={{ display: 'none' }}
                id="raised-button-file"
                type="file"
                onChange={handleFileChange}
            />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }} component={"div"}>
                <label htmlFor="raised-button-file">
                    <Button variant="contained" component="span">
                        Choisir un fichier 3D
                    </Button>
                </label>
                <Button 
                    variant="contained" 
                    color="primary" 
                    onClick={() => setIsImageSelectorOpen(true)}
                    disabled={availableImages.length === 0}
                >
                    Choisir une image
                </Button>
            </Box>
            {selectedFile && <Typography>{selectedFile.name}</Typography>}
            {availableImages.length === 0 && (
                <Typography color="error" sx={{ mt: 2 }}>
                    Aucune image disponible. Toutes les images ont déjà un modèle 3D associé.
                </Typography>
            )}
            <Button 
                variant="contained" 
                color="primary" 
                onClick={editingObject ? handleEdit : handleUpload}
                disabled={(!selectedFile && !editingObject) || (!newObjectName && !editingObject) || !selectedImageId || availableImages.length === 0}
                fullWidth
                sx={{ mt: 2 }}
            >
                {editingObject ? 'Mettre à jour' : 'Uploader'}
            </Button>
        </Box>
    );

    return (
        <Box component={"div"}>
            <Typography variant="h4" gutterBottom>Gestion des Objets 3D</Typography>

            <List>
                {objects.map((object) => {
                    const associatedImage = getImageForObject(object.image_id);
                    return (
                        <ListItem key={object.id} sx={{ border: '1px solid #ddd', mb: 2, borderRadius: 2 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }} component={"div"}>
                                {associatedImage && (
                                    <img src={associatedImage.src} alt={associatedImage.alt} style={{ width: 100, height: 100, objectFit: 'cover', marginRight: 16 }} />
                                )}
                                <ListItemText 
                                    primary={object.name} 
                                    secondary={
                                        <>
                                            <Typography component="span" variant="body2">Fichier: {object.src}</Typography>
                                            <br />
                                            <Typography component="span" variant="body2">Date d'upload: {new Date(object.upload_date).toLocaleString()}</Typography>
                                            {associatedImage && (
                                                <>
                                                    <br />
                                                    <Typography component="span" variant="body2">Image associée: {associatedImage.title}</Typography>
                                                </>
                                            )}
                                        </>
                                    }
                                />
                                <IconButton onClick={() => {
                                    setEditingObject(object);
                                    setIsUploadModalOpen(true);
                                }}>
                                    <Edit />
                                </IconButton>
                                <IconButton onClick={() => handleDelete(object.id)}>
                                    <Delete />
                                </IconButton>
                            </Box>
                        </ListItem>
                    );
                })}
            </List>

            <Fab 
                color="primary" 
                aria-label="add" 
                onClick={() => setIsUploadModalOpen(true)}
                sx={{ position: 'fixed', bottom: 16, right: 16 }}
            >
                <Add />
            </Fab>

            <Modal
                open={isUploadModalOpen}
                onClose={() => {
                    setIsUploadModalOpen(false);
                    setEditingObject(null);
                    setNewObjectName('');
                    setSelectedFile(null);
                    setSelectedImageId(null);
                }}
            >
                {uploadModalContent}
            </Modal>

            <ImageSelector
                open={isImageSelectorOpen}
                onClose={() => setIsImageSelectorOpen(false)}
                images={availableImages}
                onSelectImage={(imageId) => {
                    setSelectedImageId(imageId);
                    setIsImageSelectorOpen(false);
                }}
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={(e, page) => setCurrentPage(page)}
                categoryId={4}
            />
        </Box>
    );
};

export default Object3DManagement;