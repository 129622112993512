import React, { useState, useEffect } from 'react';
import { Container, TextField, Button, List, ListItem, ListItemText, IconButton, Typography, Box } from '@mui/material';
import { Edit, Delete, Save } from '@mui/icons-material';
import { api } from '../../api';

interface Category {
    id: number;
    name: string;
}

export const CategoryManagement: React.FC = () => {
    const [categories, setCategories] = useState<Category[]>([]);
    const [newCategoryName, setNewCategoryName] = useState('');
    const [editingCategory, setEditingCategory] = useState<Category | null>(null);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        fetchCategories();
    }, []);

    const fetchCategories = async () => {
        try {
            const data = await api.get('/categories');
            setCategories(data);
            setError(null);
        } catch (error) {
            console.error('Error fetching categories:', error);
            setError('Failed to fetch categories. Please try again.');
        }
    };

    const handleAddCategory = async (e: React.FormEvent) => {
        e.preventDefault();
      
        if (!newCategoryName.trim()) {
          setError('Category name cannot be empty.');
          return;
        }
      
        const token = localStorage.getItem('token');
      
        if (!token) {
          setError('You are not authenticated. Please log in.');
          return;
        }
      
        try {
          // Attempt to add a new category
          await api.post('/categories', { name: newCategoryName }, token);
          setNewCategoryName('');
          fetchCategories();
          setError(null);
        } catch (error) {
          console.error('Error adding category:', error);
          setError('Failed to add category. Please try again.');
        }
      };
      
      const handleEditCategory = async (category: Category) => {
        if (editingCategory && editingCategory.id === category.id) {
          const token = localStorage.getItem('token');
      
          if (!token) {
            setError('You are not authenticated. Please log in.');
            return;
          }
      
          try {
            await api.put(`/categories/${category.id}`, { name: editingCategory.name }, token);
            setEditingCategory(null);
            fetchCategories();
            setError(null);
          } catch (error) {
            console.error('Error updating category:', error);
            setError('Failed to update category. Please try again.');
          }
        } else {
          setEditingCategory(category);
        }
      };
      
      const handleDeleteCategory = async (id: number) => {
        if (window.confirm('Are you sure you want to delete this category?')) {
          const token = localStorage.getItem('token');
      
          if (!token) {
            setError('You are not authenticated. Please log in.');
            return;
          }
      
          try {
            await api.delete(`/categories/${id}`, token);
            fetchCategories();
            setError(null);
          } catch (error) {
            console.error('Error deleting category:', error);
            setError('Failed to delete category. Please try again.');
          }
        }
      };
      

    return (
        <Container maxWidth="sm">
            <Typography variant="h4" component="h2" gutterBottom>
                Manage Categories
            </Typography>
            
            <Box component="form" onSubmit={handleAddCategory} mb={4}>
                <TextField
                    label="New category name"
                    value={newCategoryName}
                    onChange={(e) => setNewCategoryName(e.target.value)}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                />
                <Button type="submit" variant="contained" color="primary" fullWidth>
                    Add Category
                </Button>
            </Box>

            <List>
                {categories.map((category) => (
                    <ListItem key={category.id} divider>
                        {editingCategory && editingCategory.id === category.id ? (
                            <TextField
                                value={editingCategory.name}
                                onChange={(e) => setEditingCategory({ ...editingCategory, name: e.target.value })}
                                variant="outlined"
                                fullWidth
                            />
                        ) : (
                            <ListItemText primary={category.name} />
                        )}
                        <IconButton onClick={() => handleEditCategory(category)} color="primary">
                            {editingCategory && editingCategory.id === category.id ? <Save /> : <Edit />}
                        </IconButton>
                        <IconButton onClick={() => handleDeleteCategory(category.id)} color="error">
                            <Delete />
                        </IconButton>
                    </ListItem>
                ))}
            </List>
        </Container>
    );
};
