import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  CircularProgress,
  Alert,
  Typography,
} from "@mui/material";
import 'react-quill/dist/quill.snow.css';
import DOMPurify from 'dompurify';
import { api } from "../api";

interface Article {
  updated_at: string | number | Date;
  id: number;
  title: string;
  content: string;
}

export const ArticleScreen: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [article, setArticle] = useState<Article | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchArticle = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const data = await api.get(`/articles/${id}`);
        setArticle(data);
      } catch (error) {
        console.error("Error fetching article:", error);
        setError("Failed to load the article. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchArticle();
  }, [id]);

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  if (!article) {
    return <Alert severity="info">Article not found.</Alert>;
  }

  return (
    <Box sx={{ maxWidth: '800px', margin: 'auto', padding: 3 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        {article.title}
      </Typography>
      <Typography variant="subtitle1" color="text.secondary" gutterBottom>
        Last updated: {new Date(article.updated_at).toLocaleDateString()}
      </Typography>
      <Box sx={{ mt: 4 }}>
        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(article.content) }} />
      </Box>
    </Box>
  );
};

export default ArticleScreen;
