import React, { useState, useEffect } from 'react';
import { Container, Typography, Paper, Box, List, ListItem, ListItemText, CircularProgress, Alert } from '@mui/material';
import { api } from '../../api';

interface DashboardStats {
    totalImages: number;
    totalCategories: number;
    totalArticles: number;
    totalProjects: number;
    totalObjects3D: number;
    recentUploads: { id: number; title: string; category: string; date: string }[];
}

export const Dashboard: React.FC = () => {
    const [stats, setStats] = useState<DashboardStats | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        fetchDashboardStats();
    }, []);

    const fetchDashboardStats = async () => {
        setLoading(true);
        setError(null);
        try {
            const token = localStorage.getItem('token');
            const data = await api.get('/dashboard-stats', token || undefined);
            setStats(data);
        } catch (error) {
            console.error('Error fetching dashboard stats:', error);
            setError('Failed to load dashboard stats. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    if (loading) return <CircularProgress />;
    if (error) return <Alert severity="error">{error}</Alert>;
    if (!stats) return <Typography>No data available</Typography>;

    return (
        <Container maxWidth="lg">
            <Typography variant="h4" component="h2" gutterBottom>
                Tableau de bord
            </Typography>
            
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 3 }} component={"div"}>
                <Paper sx={{ p: 3, flex: '1 1 300px' }}>
                    <Typography variant="h6" gutterBottom>
                        Statistiques
                    </Typography>
                    <Typography>Total d'images: {stats.totalImages}</Typography>
                    <Typography>Total de catégories: {stats.totalCategories}</Typography>
                    <Typography>Total d'articles: {stats.totalArticles}</Typography>
                    <Typography>Total de projets: {stats.totalProjects}</Typography>
                    <Typography>Total d'objets 3D: {stats.totalObjects3D}</Typography>
                </Paper>

                <Paper sx={{ p: 3, flex: '1 1 300px' }}>
                    <Typography variant="h6" gutterBottom>
                        Téléchargements récents
                    </Typography>
                    <List>
                        {stats.recentUploads.map(upload => (
                            <ListItem key={upload.id}>
                                <ListItemText
                                    primary={upload.title}
                                    secondary={`${upload.category} - ${new Date(upload.date).toLocaleDateString()}`}
                                />
                            </ListItem>
                        ))}
                    </List>
                </Paper>
            </Box>
        </Container>
    );
};
