import React, { useState } from 'react';
import { Dashboard } from '../components/admin/Dashboard';
import { CategoryManagement } from '../components/admin/CategoryManagement';
import { ContentManager } from '../components/admin/ContentManager';
import ProjectManagement from '../components/admin/ProjectManagement';
import { Container, Typography, Tabs, Tab, Box, useMediaQuery, useTheme } from '@mui/material';
import Object3DManagement from '../components/admin/Object3DManagement';
import { ArticleManager } from '../components/admin/ArticleManager';

export const AdminPage: React.FC = () => {
    const [activeTab, setActiveTab] = useState('dashboard');
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const renderContent = () => {
        switch (activeTab) {
            case 'dashboard':
                return <Dashboard />;
            case 'categories':
                return <CategoryManagement />;
            case 'content':
                return <ContentManager />;
            case 'projects':
                return <ProjectManagement />;
            case 'objects3d':
                return <Object3DManagement />;
            case 'articles':
                return <ArticleManager />;
            default:
                return <Dashboard />;
        }
    };

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setActiveTab(newValue);
    };

    return (
        <Container>
            <Typography variant="h3" component="h1" gutterBottom>
                Administration
            </Typography>
            
            <Tabs 
                value={activeTab} 
                onChange={handleChange} 
                aria-label="admin tabs"
                variant={isSmallScreen ? "scrollable" : "standard"}
                scrollButtons={isSmallScreen ? "auto" : false}
                allowScrollButtonsMobile
                sx={{
                    [`& .MuiTabs-flexContainer`]: {
                        flexWrap: isSmallScreen ? 'nowrap' : 'wrap',
                    },
                }}
            >
                <Tab label="Tableau de bord" value="dashboard" />
                <Tab label="Gestion des catégories" value="categories" />
                <Tab label="Gestion du contenu" value="content" />
                <Tab label="Gestion des projets" value="projects" />
                <Tab label="Gestion des objets 3D" value="objects3d" />
                <Tab label="Gestion des articles" value="articles" />
            </Tabs>

            <Box mt={3} component={"div"}>
                {renderContent()}
            </Box>
        </Container>
    );
};

export default AdminPage;