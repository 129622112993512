import React from "react";
import { FaInstagram, FaGithub, FaLinkedin } from "react-icons/fa6";
import {
    Container,
  Box,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Link,
} from "@mui/material";

export const SocialLinks: React.FC = () => {
  return (
    <Container maxWidth="lg">
      <Box mt={5}>
        <Typography variant="h5" component="h3" fontWeight="extrabold">
          Liens sociaux
        </Typography>
        <List>
          <ListItem>
            <ListItemIcon>
              <FaInstagram />
            </ListItemIcon>
            <ListItemText>
              <Link
                href="https://www.instagram.com/lepidopterae1201/"
                target="_blank"
                underline="none"
              >
                Instagram
              </Link>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <FaLinkedin />
            </ListItemIcon>
            <ListItemText>
              <Link
                href="https://www.linkedin.com/in/alexandre-joniec/"
                target="_blank"
                underline="none"
              >
                LinkedIn
              </Link>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <FaGithub />
            </ListItemIcon>
            <ListItemText>
              <Link
                href="https://github.com/Lepidopterae1201"
                target="_blank"
                underline="none"
              >
                Github
              </Link>
            </ListItemText>
          </ListItem>
        </List>
      </Box>
    </Container>
  );
};
