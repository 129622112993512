import React, { useState, useEffect } from "react";
import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Modal,
  Box,
  Pagination,
  CircularProgress,
} from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { ModelViewer } from "./ModelViewer";
import { api } from "../api";

interface ContentItem {
  id: number;
  title: string;
  src: string;
  alt: string;
  description: string;
  category: string;
  articleId?: number;
  modelUrl?: string;
}

interface Article {
  id: number;
  content: string;
}

interface ContentGalleryProps {
  category: number;
  onItemClick?: (item: ContentItem) => void;
  showModal?: boolean;
}

const ContentGallery: React.FC<ContentGalleryProps> = ({
  category,
  onItemClick,
  showModal = true,
}) => {
  const [items, setItems] = useState<ContentItem[]>([]);
  const [selectedItem, setSelectedItem] = useState<ContentItem | null>(null);
  const [selectedArticle, setSelectedArticle] = useState<Article | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    fetchItems();
  }, [category, currentPage]);

  useEffect(() => {
    if (selectedItem && selectedItem.articleId) {
      fetchArticle(selectedItem.articleId);
    } else {
      setSelectedArticle(null);
    }
  }, [selectedItem]);

  const fetchItems = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const data = await api.get(`/images?category=${category}&page=${currentPage}`);
      setItems(data.images);
      setTotalPages(data.totalPages);
    } catch (error) {
      console.error("Erreur lors de la récupération des données:", error);
      setError("Une erreur est survenue lors du chargement des images. Veuillez réessayer.");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchArticle = async (articleId: number) => {
    try {
      const article = await api.get(`/articles/${articleId}`);
      setSelectedArticle(article);
    } catch (error) {
      console.error("Erreur lors de la récupération de l'article:", error);
      setError("Impossible de charger l'article associé. Veuillez réessayer.");
      setSelectedArticle(null);
    }
  };

  const handleItemClick = (item: ContentItem) => {
    setSelectedItem(item);
    if (onItemClick) {
      onItemClick(item);
    }
  };

  const handleCloseModal = () => {
    setSelectedItem(null);
    setSelectedArticle(null);
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Box sx={{ flexGrow: 1, px: 4, py: 8 }} component={"div"}>
      <Grid container spacing={3}>
        {items.map((item) => (
          <Grid item xs={12} sm={6} md={4} key={item.id}>
            <Card
              onClick={() => handleItemClick(item)}
              sx={{ cursor: "pointer" }}
            >
              <CardMedia
                component="img"
                height="512"
                image={`${item.src}`}
                alt={item.alt}
              />
              <CardContent>
                <Typography variant="body2" color="textSecondary">
                  {item.title}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {showModal && selectedItem && (
  <Modal
    open={!!selectedItem}
    onClose={handleCloseModal}
    aria-labelledby="modal-title"
    aria-describedby="modal-description"
  >
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "90%",
        maxWidth: "1200px",
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 4,
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        maxHeight: "90vh",
        overflowY: "auto",
      }}
      component={"div"}
    >
      <Box
        sx={{
          width: { xs: "100%", md: "50%" },
          pr: { md: 2 },
          mb: { xs: 2, md: 0 },
        }}
        component={"div"}
      >
        <Box
          component="img"
          sx={{
            width: "100%",
            height: "auto",
            maxHeight: "70vh",
            objectFit: "contain",
          }}
          src={`${selectedItem.src}`}
          alt={selectedItem.alt}
        />
      </Box>
      <Box
        sx={{
          width: { xs: "100%", md: "50%" },
          pl: { md: 2 },
          display: "flex",
          flexDirection: "column",
        }}
        component={"div"}
      >
        <Typography variant="h5" component="h2" gutterBottom>
          {selectedItem.title}
        </Typography>
        
        {selectedArticle ? (
          <Box sx={{ mb: 2, flexGrow: 1, overflowY: "auto" }} component={"div"}>
            <ReactQuill 
              value={selectedArticle.content} 
              readOnly={true} 
              theme="snow"
              modules={{ toolbar: false }}
            />
          </Box>
        ) : (
          <Typography variant="body1" sx={{ mb: 2 }}>
            {selectedItem.description}
          </Typography>
        )}

        {selectedItem.modelUrl && (
          <Box sx={{ height: "400px", mt: 2 }} component={"div"}>
            <ModelViewer modelUrl={selectedItem.modelUrl} />
          </Box>
        )}
      </Box>
    </Box>
  </Modal>
)}

      <Box
        sx={{ display: "flex", justifyContent: "center", mt: 4 }}
        component={"div"}
      >
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
        />
      </Box>
    </Box>
  );
};

export default ContentGallery;
