import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Pagination,
  Fab,
  CircularProgress,
  Alert,
} from "@mui/material";
import { Edit, Delete, Add, Description, Cancel } from "@mui/icons-material";
import { NavLink } from "react-router-dom";
import { api } from "../../api";

interface Project {
  id: number;
  title: string;
  description: string;
  image_id: number;
  image_src: string;
  article_id?: number;
}

interface Image {
  id: number;
  title: string;
  src: string;
}

const getImageSrcById = (imageId: number, images: Image[]): string => {
  const image = images.find((img) => img.id === imageId);
  return image ? image.src : "";
};

const ProjectManagement: React.FC = () => {
  const [projects, setProjects] = useState<Project[]>([]);
  const [newProject, setNewProject] = useState({
    title: "",
    description: "",
    image_id: 0,
  });
  const [editingProject, setEditingProject] = useState<Project | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddProjectModalOpen, setIsAddProjectModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [availableImages, setAvailableImages] = useState<Image[]>([]);
  const [isLoadingImages, setIsLoadingImages] = useState(false);
  const [imageError, setImageError] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchProjects();
    fetchAvailableImages(currentPage);
  }, [currentPage]);

  const fetchAvailableImages = async (page = 1) => {
    setIsLoadingImages(true);
    setError(null);
    try {
      const token = localStorage.getItem('token');
      const data = await api.get(`/images/available?category=8&page=${page}`, token || undefined);
      setAvailableImages(data.images);
      setTotalPages(data.totalPages);
      if (data.images.length === 0) {
        setError("Aucune image disponible. Veuillez d'abord ajouter des images dans la catégorie appropriée.");
      }
    } catch (error) {
      console.error("Error fetching available images:", error);
      setError("Erreur lors de la récupération des images. Veuillez réessayer.");
    } finally {
      setIsLoadingImages(false);
    }
  };

  const fetchProjects = async () => {
    try {
      const token = localStorage.getItem('token');
      const data = await api.get("/projects", token || undefined);
      setProjects(data);
      setError(null);
    } catch (error) {
      console.error("Error fetching projects:", error);
      setError("Échec du chargement des projets. Veuillez réessayer.");
    }
  };

  const handleAddProject = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!newProject.title.trim() || !newProject.description.trim()) {
        setError('Both title and description are required.');
        return;
    }

    try {
        const token = localStorage.getItem('token');
        if (!token) {
            setError('You are not authenticated. Please log in.');
            return;
        }

        const data = await api.post("/projects", newProject, token);
        console.log("Project created successfully:", data);
        setNewProject({ title: "", description: "", image_id: 0 });
        fetchProjects();
        setIsAddProjectModalOpen(false);
        setError(null);
    } catch (error) {
        console.error("Error adding project:", error);
        setError("An error occurred while creating the project. Please try again.");
    }
};

const handleEditProject = async (project: Project) => {
  if (!editingProject) {
      setError('No project selected for editing.');
      return;
  }

  try {
      const token = localStorage.getItem('token');
      if (!token) {
          setError('You are not authenticated. Please log in.');
          return;
      }

      await api.put(`/projects/${project.id}`, {
          title: editingProject.title.trim(),
          description: editingProject.description.trim(),
          image_id: editingProject.image_id,
      }, token);
      
      setEditingProject(null);
      fetchProjects();
      setError(null);
  } catch (error) {
      console.error("Error updating project:", error);
      setError("Failed to update the project. Please try again.");
  }
};

  const handleCancelEdit = () => {
    setEditingProject(null);
  };

  const handleDeleteProject = async (id: number) => {
    if (window.confirm("Are you sure you want to delete this project?")) {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                setError('You are not authenticated. Please log in.');
                return;
            }

            await api.delete(`/projects/${id}`, token);
            setProjects(prevProjects => prevProjects.filter(project => project.id !== id));
            fetchAvailableImages(currentPage);
            setError(null);
        } catch (error) {
            console.error("Error deleting project:", error);
            setError("Failed to delete the project. Please try again.");
        }
    }
};

  const handleImageSelect = (imageId: number) => {
    if (editingProject) {
      setEditingProject({
        ...editingProject,
        image_id: imageId,
        image_src: getImageSrcById(imageId, availableImages),
      });
    } else {
      setNewProject({ ...newProject, image_id: imageId });
    }
    setIsModalOpen(false);
  };

  const isFormValid = () => {
    return (
      newProject.title.trim() !== "" &&
      newProject.description.trim() !== "" &&
      newProject.image_id !== 0
    );
  };

  const areImagesAvailable = () => {
    return availableImages.length > 0;
  };

  return (
    <Container maxWidth="md">
      <Typography variant="h4" component="h2" gutterBottom>
        Gestion des Projets
      </Typography>

      <List>
        {projects.map((project) => (
          <ListItem key={project.id} divider>
            {editingProject && editingProject.id === project.id ? (
              <Box
                component="form"
                onSubmit={(e) => {
                  e.preventDefault();
                  handleEditProject(project);
                }}
                width="100%"
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
              >
                <Box display="flex" width="100%" mb={2}>
                  <Box flexShrink={0} mr={2} component={"div"}>
                    <img
                      src={
                        editingProject.image_src.startsWith("/")
                          ? editingProject.image_src
                          : `/${editingProject.image_src}`
                      }
                      alt="Selected"
                      style={{
                        width: "150px",
                        height: "150px",
                        objectFit: "cover",
                      }}
                    />
                  </Box>
                  <Box flexGrow={1} component={"div"}>
                    <TextField
                      value={editingProject.title}
                      onChange={(e) =>
                        setEditingProject({
                          ...editingProject,
                          title: e.target.value,
                        })
                      }
                      variant="outlined"
                      fullWidth
                      required
                      margin="normal"
                    />
                    <TextField
                      value={editingProject.description}
                      onChange={(e) =>
                        setEditingProject({
                          ...editingProject,
                          description: e.target.value,
                        })
                      }
                      variant="outlined"
                      fullWidth
                      required
                      margin="normal"
                      multiline
                      rows={3}
                    />
                  </Box>
                </Box>
                <Box display="flex" gap={2} mt={2} component={"div"}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setIsModalOpen(true)}
                  >
                    Changer l'image
                  </Button>
                  <Button type="submit" variant="contained" color="primary">
                    Sauvegarder
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleCancelEdit}
                    startIcon={<Cancel />}
                  >
                    Annuler
                  </Button>
                </Box>
              </Box>
            ) : (
              <Box
                display="flex"
                alignItems="center"
                width="100%"
                component={"div"}
              >
                <Box flexShrink={0} mr={2} component={"div"}>
                  <img
                    src={
                      project.image_src.startsWith("/")
                        ? project.image_src
                        : `/${project.image_src}`
                    }
                    alt={project.title}
                    style={{
                      width: "150px",
                      height: "150px",
                      objectFit: "cover",
                    }}
                  />
                </Box>
                <Box flexGrow={1} component={"div"}>
                  <ListItemText
                    primary={project.title}
                    secondary={project.description}
                  />
                </Box>
                <IconButton
                  onClick={() => setEditingProject(project)}
                  color="primary"
                >
                  <Edit />
                </IconButton>
                <IconButton
                  onClick={() => handleDeleteProject(project.id)}
                  color="error"
                >
                  <Delete />
                </IconButton>
                {project.article_id && (
                  <IconButton
                    component={NavLink}
                    to={`/article-editor/${project.article_id}`}
                    color="secondary"
                  >
                    <Description />
                  </IconButton>
                )}
              </Box>
            )}
          </ListItem>
        ))}
      </List>

      <Dialog
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>Choisir une image</DialogTitle>
        <DialogContent>
          {isLoadingImages ? (
            <CircularProgress />
          ) : imageError ? (
            <Alert severity="error">{imageError}</Alert>
          ) : (
            <Grid container spacing={2}>
              {availableImages.map((image) => (
                <Grid item xs={12} sm={6} md={4} key={image.id}>
                  <Card
                    onClick={() => handleImageSelect(image.id)}
                    sx={{ cursor: "pointer" }}
                  >
                    <CardMedia
                      component="img"
                      height="140"
                      image={`/${image.src}`}
                      alt={image.title}
                    />
                    <CardContent>
                      <Typography variant="body2" color="textSecondary">
                        {image.title}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsModalOpen(false)} color="primary">
            Annuler
          </Button>
          <Box
            mt={4}
            display="flex"
            justifyContent="center"
            width="100%"
            component={"div"}
          >
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={(e, page) => setCurrentPage(page)}
              color="primary"
            />
          </Box>
        </DialogActions>
      </Dialog>

      <Fab
        color="primary"
        aria-label="add"
        onClick={() => setIsAddProjectModalOpen(true)}
        sx={{ position: "fixed", bottom: 16, right: 16 }}
      >
        <Add />
      </Fab>

      <Dialog
        open={isAddProjectModalOpen}
        onClose={() => setIsAddProjectModalOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Ajouter un Projet</DialogTitle>
        <DialogContent>
          <Box component="form" onSubmit={handleAddProject}>
            <TextField
              label="Titre"
              value={newProject.title}
              onChange={(e) =>
                setNewProject({ ...newProject, title: e.target.value })
              }
              fullWidth
              required
              margin="normal"
            />
            <TextField
              label="Description"
              value={newProject.description}
              onChange={(e) =>
                setNewProject({
                  ...newProject,
                  description: e.target.value,
                })
              }
              fullWidth
              required
              margin="normal"
            />
            <Box display="flex" gap={2} mt={2} component={"div"}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setIsModalOpen(true)}
                disabled={!areImagesAvailable() || isLoadingImages}
              >
                Choisir une image
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={!isFormValid()}
              >
                Ajouter un Projet
              </Button>
            </Box>
            {imageError && (
              <Alert severity="error" sx={{ mt: 2 }}>
                {imageError}
              </Alert>
            )}
            {newProject.image_id !== 0 && (
              <img
                src={`/${
                  availableImages.find((img) => img.id === newProject.image_id)
                    ?.src
                }`}
                alt="Selected"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  marginTop: "16px",
                }}
              />
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default ProjectManagement;
