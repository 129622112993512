import React, { useState, useEffect } from "react";
import {
  Container,
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Divider,
  Button,
  CircularProgress,
  Alert,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { FaPhp, FaPython, FaJs, FaReact, FaNode, FaDocker, FaGit, FaAndroid } from "react-icons/fa";
import { SiTypescript, SiJquery, SiAngular, SiVuedotjs, SiDjango, SiMysql, SiPostgresql, SiMongodb, SiBootstrap, SiTailwindcss, SiMaterialdesign, SiSymfony } from "react-icons/si";
import { api } from "../api";

interface Project {
  id: number;
  title: string;
  description: string;
  image_src: string;
  image_category_id: number;
  article_id: number;
}


const skills = {
  "Langages de programmation": [
    { name: "PHP", icon: <FaPhp /> },
    { name: "Python", icon: <FaPython /> },
    { name: "JavaScript", icon: <FaJs /> },
    { name: "TypeScript", icon: <SiTypescript /> },
    { name: "Kotlin", icon: <FaAndroid />}
  ],
  "Frameworks et Bibliothèques": [
    { name: "Jquery", icon: <SiJquery /> },
    { name: "Vue.js", icon: <SiVuedotjs /> },
    { name: "Angular", icon: <SiAngular /> },
    { name: "React", icon: <FaReact /> },
    { name: "Symphony", icon: <SiSymfony /> },
    { name: "Django", icon: <SiDjango /> },
    { name: "Node.js", icon: <FaNode /> },
  ],
  "Bases de données": [
    { name: "MySQL", icon: <SiMysql /> },
    { name: "PostgreSQL", icon: <SiPostgresql /> },
    { name: "MongoDB", icon: <SiMongodb /> },
  ],
  "Outils et Technologies": [
    { name: "Bootstrap", icon: <SiBootstrap /> },
    { name: "Tailwind CSS", icon: <SiTailwindcss /> },
    { name: "Material-UI", icon: <SiMaterialdesign /> },
    { name: "Docker", icon: <FaDocker /> },
    { name: "Git", icon: <FaGit /> },
  ],
};

export const Development: React.FC = () => {
  const [projects, setProjects] = useState<Project[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchProjects();
  }, []);

  const fetchProjects = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const data = await api.get('/projects');
      setProjects(data);
    } catch (error) {
      console.error("Error fetching projects:", error);
      setError('Failed to load projects. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleProjectClick = (categoryId: number) => {
    navigate("/creations", { state: { categoryId } });
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  return (
    <Container maxWidth="lg" sx={{ pt: 8, mb: 12 }}>
      <Box mt={5} component={"div"}>
        <Typography variant="h4" component="h2" gutterBottom>
          Développement
        </Typography>
        <Typography variant="body1" gutterBottom>
          Je suis un développeur polyvalent avec une passion pour l'apprentissage continu et l'exploration de nouvelles technologies. Voici un aperçu de mes compétences, de mes expériences professionnelles et de mes projets.
        </Typography>

        {Object.entries(skills).map(([category, skillsList], index) => (
          <Box key={index} mb={4} component={"div"}>
            <Typography variant="h6" component="h3" gutterBottom>
              {category}
            </Typography>
            <Grid container spacing={2}>
              {skillsList.map((skill, index) => (
                <Grid item xs={6} sm={4} md={3} key={index}>
                  <Box display="flex" alignItems="center" component={"div"}>
                    {skill.icon}
                    <Typography variant="body1" sx={{ ml: 1 }}>
                      {skill.name}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        ))}
        <Divider sx={{ my: 4 }} />
        <Typography variant="h6" component="h3" gutterBottom>
          Mes Projets
        </Typography>
        <Grid container spacing={3}>
          {projects.map((project, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card sx={{ height: "100%" }}>
                <CardMedia
                  component="img"
                  height="140"
                  image={`${project.image_src}`}
                  alt={project.title}
                />
                <CardContent>
                  <Typography variant="h6" component="div">
                    {project.title}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {project.description}
                  </Typography>
                  <Box mt={2} component={"div"}>
                    <Button
                      onClick={() => handleProjectClick(project.image_category_id)}
                      variant="contained"
                      color="primary"
                    >
                      Voir l'article
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Divider sx={{ my: 4 }} />
        <Typography variant="h6" component="h3" gutterBottom>
          Expériences Professionnelles
        </Typography>
        <Typography variant="body1" gutterBottom>
          J'ai principalement travaillé pour des startups en alternance où j'ai occupé le rôle de développeur fullstack. J'ai travaillé en équipe en apportant mes connaissances au besoin pour aider ceux dans le besoin ou en codant ma partie du projet en méthode agile.
        </Typography>
        <Divider sx={{ my: 4 }} />
        <Typography variant="h6" component="h3" gutterBottom>
          Réalisations et Certifications
        </Typography>
        <Typography variant="body1" gutterBottom>
          J'ai obtenu un BTS SIO Option B (SLAM) en 2020.
        </Typography>
        <Divider sx={{ my: 4 }} />
        <Typography variant="h6" component="h3" gutterBottom>
          Objectifs et Aspirations
        </Typography>
        <Typography variant="body1" gutterBottom>
          Je développe principalement pour le personnel, mais je ne suis pas contre de participer à de nouveaux projets. J'explore toujours de nouveaux projets selon les besoins que je ressens ou les idées qui me viennent en tête. J'aime surtout le côté UX/UI ou simplement passer du temps sur le front-end des applications.
        </Typography>
      </Box>
    </Container>
  );
};
