import React, { useState, useEffect, useRef, useCallback } from "react";
import { useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Container, Typography, Box, Button } from "@mui/material";
import { api } from "../api";

export const ArticleEditor: React.FC = () => {
  const { articleId } = useParams<{ articleId: string }>();
  const [content, setContent] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const reactQuillRef = useRef<ReactQuill>(null);

  useEffect(() => {
    fetchArticleContent();
  }, [articleId]);

  const fetchArticleContent = async () => {
    try {
      const data = await api.get(`/articles/${articleId}`);
      setContent(data.content);
      setError(null);
    } catch (error) {
      console.error("Error fetching article content:", error);
      setError("Failed to load article content. Please try again.");
    }
  };

  const handleSave = async () => {
    try {
      await api.put(`/articles/${articleId}`, { content });
      alert("Article saved successfully!");
      setError(null);
    } catch (error) {
      console.error("Error saving article:", error);
      setError("Failed to save article. Please try again.");
    }
  };

  const imageHandler = useCallback(() => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();
    input.onchange = async () => {
      if (input.files && input.files[0]) {
        const file = input.files[0];
        const formData = new FormData();
        formData.append("image", file);

        const title = prompt("Enter image title:");
        const alt = prompt("Enter alt text for the image:");
        const description = prompt("Enter image description:");
        const categoryId = prompt("Enter category ID for the image:");

        if (title && alt && description && categoryId) {
          formData.append("title", title);
          formData.append("alt", alt);
          formData.append("description", description);
          formData.append("categoryId", categoryId);

          try {
            const result = await api.post('/images/upload-image', formData);
            const quillEditor = reactQuillRef.current?.getEditor();
            const range = quillEditor?.getSelection()?.index || 0;
            quillEditor?.insertEmbed(range, "image", result.imageUrl);
            setError(null);
          } catch (error) {
            console.error("Error uploading image:", error);
            setError("Failed to upload image. Please try again.");
          }
        } else {
          setError("All fields are required for image upload.");
        }
      }
    };
  }, []);

  const modules = {
    toolbar: {
      container: [
        [{ header: "1" }, { header: "2" }, { font: [] }],
        [{ size: [] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
        ["link", "image", "video"],
        ["code-block"],
        ["clean"],
      ],
      handlers: {
        image: imageHandler,
      },
    },
  };

  return (
    <Container>
      <Typography variant="h4" component="h2" gutterBottom>
        Éditeur d'Article
      </Typography>
      <Box mb={3} component={"div"}>
        <ReactQuill
          ref={reactQuillRef}
          value={content}
          onChange={setContent}
          placeholder="Start writing..."
          modules={modules}
          formats={[
            "header",
            "font",
            "size",
            "bold",
            "italic",
            "underline",
            "strike",
            "blockquote",
            "list",
            "bullet",
            "indent",
            "link",
            "image",
            "video",
            "code-block",
          ]}
        />
      </Box>
      <Button variant="contained" color="primary" onClick={handleSave}>
        Sauvegarder
      </Button>
    </Container>
  );
};

export default ArticleEditor;
