import React, { useState, useEffect, useMemo } from 'react';
import {
  Container,
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  Select,
  MenuItem,
  Modal,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  TextField,
  Alert,
} from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { api } from '../../api';

interface Article {
  id: number;
  title: string;
  content: string;
  category: string;
  category_name: string;
  created_at: string;
  updated_at: string;
  image_id: number | null;
}

interface Category {
  id: number;
  name: string;
}

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  maxHeight: '90vh',
  overflow: 'auto',
};

export const ArticleManager: React.FC = () => {
  const [articles, setArticles] = useState<Article[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<number | 'all'>('all');
  const [editingArticle, setEditingArticle] = useState<Article | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editTitle, setEditTitle] = useState('');
  const [editContent, setEditContent] = useState('');
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    fetchArticles();
  }, [selectedCategory]);

  const fetchArticles = async () => {
    const token = localStorage.getItem('token');
    try {
      let endpoint = '/articles';
      if (selectedCategory !== 'all') {
        endpoint += `/category/${selectedCategory}`;
      }
      const data = await api.get(endpoint, token || undefined);
      setArticles(data.articles || data);
      setError(null);
    } catch (error) {
      console.error('Error fetching articles:', error);
      setError('Failed to fetch articles. Please try again.');
    }
  };

  const fetchCategories = async () => {
    try {
      const data = await api.get('/categories');
      setCategories(data);
      setError(null);
    } catch (error) {
      console.error('Error fetching categories:', error);
      setError('Failed to fetch categories. Please try again.');
    }
  };
  
  const handleCategoryChange = (event: SelectChangeEvent<number | string>) => {
    setSelectedCategory(event.target.value as number | 'all');
  };

  const handleEditClick = (article: Article) => {
    setEditingArticle(article);
    setEditTitle(article.title);
    setEditContent(article.content);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setEditingArticle(null);
    setEditTitle('');
    setEditContent('');
  };

  const handleSaveArticle = async () => {
    if (!editingArticle) {
      setError('No article selected for editing.');
      return;
    }
  
    const token = localStorage.getItem('token');
  
    if (!token) {
      setError('You are not authenticated. Please log in.');
      return;
    }
  
    try {
      await api.put(
        `/articles/${editingArticle.id}`,
        { title: editTitle.trim(), content: editContent.trim() },
        token
      );
  
      fetchArticles();
      handleCloseModal();
      setError(null);
    } catch (error) {
      console.error('Error updating article:', error);
      setError('Failed to update article. Please try again.');
    }
  };
  
  const handleDeleteArticle = async (articleId: number) => {
    const token = localStorage.getItem('token');
  
    if (!token) {
      setError('You are not authenticated. Please log in.');
      return;
    }
  
    if (window.confirm('Are you sure you want to delete this article?')) {
      try {
        await api.delete(`/articles/${articleId}`, token);
        fetchArticles();
        setError(null);
      } catch (error) {
        console.error('Error deleting article:', error);
        setError('Failed to delete article. Please try again.');
      }
    }
  };
  

  const filteredArticles = useMemo(() => {
    return selectedCategory === 'all'
      ? articles
      : articles.filter(article => article.category === selectedCategory.toString());
  }, [articles, selectedCategory]);

  return (
    <Container>
      <Typography variant="h4" component="h1" gutterBottom>
        Article Manager
      </Typography>
  
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <Box mb={3}>
        <FormControl fullWidth>
          <InputLabel id="category-select-label">Filter by Category</InputLabel>
          <Select
            labelId="category-select-label"
            value={selectedCategory}
            onChange={handleCategoryChange}
          >
            <MenuItem value="all">All Categories</MenuItem>
            {categories.map((category) => (
              <MenuItem key={category.id} value={category.id}>
                {category.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
  
      <Grid container spacing={3}>
        {filteredArticles.map((article) => (
          <Grid item xs={12} sm={6} md={4} key={article.id}>
            <Card>
              <CardContent>
                <Typography variant="h6" component="h2" gutterBottom>
                  {article.title}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Category: {article.category_name || 'Uncategorized'}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Last Updated: {new Date(article.updated_at).toLocaleString()}
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small" color="primary" onClick={() => handleEditClick(article)}>
                  Edit
                </Button>
                <Button size="small" color="error" onClick={() => handleDeleteArticle(article.id)}>
                  Delete
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="edit-article-modal"
      >
        <Box sx={modalStyle}>
          <Typography variant="h6" component="h2" gutterBottom>
            Edit Article
          </Typography>
          <TextField
            fullWidth
            label="Title"
            value={editTitle}
            onChange={(e) => setEditTitle(e.target.value)}
            margin="normal"
          />
          <ReactQuill
            value={editContent}
            onChange={setEditContent}
            style={{ height: '300px', marginBottom: '50px' }}
          />
          <Box mt={2}>
            <Button variant="contained" color="primary" onClick={handleSaveArticle}>
              Save Changes
            </Button>
            <Button variant="outlined" onClick={handleCloseModal} sx={{ ml: 2 }}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </Container>
  );
};