import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Container, Typography, Box, CircularProgress } from '@mui/material';
import { api } from '../../api';

declare global {
    interface Window {
        grecaptcha: any;
    }
}

export const AdminLogin: React.FC = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const loadRecaptchaScript = () => {
            const existingScript = document.querySelector('script[src^="https://www.google.com/recaptcha/api.js"]');
            if (existingScript) {
                return;
            }

            const script = document.createElement('script');
            script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`;
            script.async = true;
            script.defer = true;
            document.body.appendChild(script);

            script.onload = () => {
                if (typeof window.grecaptcha !== 'undefined') {
                    console.log('reCAPTCHA is ready');
                } else {
                    console.error('reCAPTCHA not defined');
                }
            };
        };

        loadRecaptchaScript();
    }, []);

    const handleLogin = async (e: React.FormEvent) => {
        e.preventDefault();
        setError('');
        setIsLoading(true);
    
        if (!username || !password) {
            setError('Username and password are required');
            setIsLoading(false);
            return;
        }

        if (typeof window.grecaptcha === 'undefined') {
            console.error('reCAPTCHA is not defined');
            setError('reCAPTCHA is not loaded. Please refresh the page and try again.');
            setIsLoading(false);
            return;
        }
    
        try {
            console.log('Attempting to execute reCAPTCHA');
            const recaptchaToken = await window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, { action: 'submit' });
            console.log('reCAPTCHA token obtained');

            console.log('Sending login request');
            const response = await api.post('/login', { 
                username, 
                password,
                'g-recaptcha-response': recaptchaToken
            });
            console.log('Login response received', response);
    
            if (response.accessToken && response.refreshToken) {
                localStorage.setItem('accessToken', response.accessToken);
                localStorage.setItem('refreshToken', response.refreshToken);
                console.log('Login successful, navigating to admin page');
                navigate('/admin');
            } else {
                console.error('Invalid response from server', response);
                throw new Error('Invalid response from server');
            }
        } catch (err) {
            console.error('Error logging in:', err);
            setError('Invalid credentials or an error occurred. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Container maxWidth="sm" sx={{ pt: 8 }}>
            <Box component="form" onSubmit={handleLogin} mt={8}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Admin Login
                </Typography>
                {error && (
                    <Typography variant="body1" color="error" gutterBottom>
                        {error}
                    </Typography>
                )}
                <TextField
                    label="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    fullWidth
                    required
                    margin="normal"
                />
                <TextField
                    label="Password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    fullWidth
                    required
                    margin="normal"
                />
                <Button 
                    type="submit" 
                    variant="contained" 
                    color="primary" 
                    fullWidth 
                    disabled={isLoading}
                >
                    {isLoading ? <CircularProgress size={24} /> : 'Login'}
                </Button>
            </Box>
        </Container>
    );
};

export default AdminLogin;